import * as types from "../actionTypes";
import axios from "axios";
import { API_URL } from '../../config';
import * as partnerTypeActions from './partnerTypeActions';
import * as fetchCoursesActions from './fetchCoursesActions';

export const fetchShopJSONFromCMSCMSRequest = () => {
    return {
        type: types.FETCH_SHOP_JSON_REQUEST
    }
}

export const fetchShopJSONFromCMSCMSSuccess = partnerRelationObj => {
    return {
        type: types.FETCH_SHOP_JSON_SUCCESS,
        payload: partnerRelationObj
    }
}

export const fetchShopJSONFromCMSCMSFailure = () => {
    return {
        type: types.FETCH_SHOP_JSON_FAILURE
    }
}

export const fetchShopJSONFromCMSCMS = () => {
    console.log("blah")
    return (dispatch) => {
        dispatch(fetchShopJSONFromCMSCMSRequest())
        axios.get(`${API_URL}/shop-json`)
            .then(res => {
                const partnerRelationObj = res.data.data.attributes.partnerRelation;
                const supplierContentObj = res.data.data.attributes.supplierContent;

                // dispatch(partnerTypeActions.setPartnerType(window.PARTNER_TYPE));
                dispatch(fetchCoursesActions.fetchCourses(window.PARTNER_TYPE, partnerRelationObj));
                dispatch(fetchShopJSONFromCMSCMSSuccess({partnerRelationObj, supplierContentObj}))
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchShopJSONFromCMSCMSFailure())
            })
    }
}
